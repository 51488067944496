// Copyright 2022, Imprivata, Inc.  All rights reserved.
import type { BannerProps } from '@imprivata-cloud/components';
import i18n from '../../i18n';

export const errors: {
  [key: string]: () => BannerProps;
} = {
  PATIENT_SEARCH_FAILED: () => ({
    type: 'error',
    message: i18n.t('patient-search.search-error.message'),
    description: i18n.t('patient-search.search-error.description'),
    duration: 5,
    datatestid: 'patient-search-result--error',
  }),
  PATIENT_DELETE_FAILED: () => ({
    type: 'error',
    message: i18n.t('patient-details.delete-error.message'),
    description: i18n.t('patient-details.delete-error.description'),
    duration: 5,
    datatestid: 'patient-delete--result',
  }),
  PATIENT_ACTIVITY_HISTORY_FAILED: () => ({
    type: 'error',
    message: i18n.t('patient-details.activity-history-error.message'),
    description: i18n.t('patient-details.activity-history-error.description'),
    duration: 5,
    datatestid: 'patient-activity-history-result--error',
  }),
  PATIENT_DETAILS_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('patient-details.get-patient-details-error'),
    duration: 5,
    datatestid: 'patient-details-get-patient--error',
  }),
  AGE_LIMIT_MINIMUM_AGE_CONFIGURATION_SAVE_FAILED: () => ({
    message: i18n.t(
      'age-limit-configuration.save-minimum-age-error.message',
    ),
    description: i18n.t(
      'age-limit.save-minimum-age-error.description',
    ),
    duration: 5,
    type: 'error',
    datatestid: 'age-limit-configuration--error',
  }),
  AGE_LIMIT_MINIMUM_AGE_GET_FAILED: () => ({
    message: i18n.t('age-limit-configuration.get-minimum-age-error.message'),
    description: i18n.t('age-limit-configuration.get-minimum-age-error.description'),
    duration: 5,
    type: 'error',
    datatestid: 'age-limit-configuration--error',
  }),
  FHIR_CONFIGURATION_SAVE_FAILED: () => ({
    message: i18n.t('fhir-configuration.save-error.message'),
    description: i18n.t('fhir-configuration.save-error.description'),
    duration: 5,
    type: 'error',
    datatestid: 'fhir-configuration--error',
  }),
  FHIR_CONFIGURATION_GET_FAILED: () => ({
    message: i18n.t('fhir-configuration.get-error.message'),
    description: i18n.t('fhir-configuration.get-error.description'),
    duration: 5,
    type: 'error',
    datatestid: 'fhir-configuration--error',
  }),
  HL7_CONFIGURATION_SYSTEM_SAVE_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.save-configuration'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-configuration--error',
  }),
  HL7_CONFIGURATION_SYSTEM_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-configuration'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-configuration--error',
  }),
  HL7_CONFIGURATION_SYSTEM_DELETE_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.delete-configuration'),
    description: i18n.t(
      'hl7-configuration.errors.delete-configuration--description',
    ),
    duration: 5,
    datatestid: 'hl7-configuration--error',
  }),
  HL7_RULE_SAVE_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.save-rule'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-rule--error',
  }),
  HL7_RULE_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-rule'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-rule--error',
  }),
  HL7_RULES_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-rules'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-rules--error',
  }),
  HL7_RULE_DELETE_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.delete-rule'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-rule--error',
  }),
  HL7_SUCCESS_MESSAGE_COUNT_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-success-message-count'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-configuration--error',
  }),
  HL7_ERRORED_MESSAGE_COUNT_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-errored-message-count'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-configuration--error',
  }),
  HL7_UNSENT_MESSAGE_COUNT_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-unsent-message-count'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-configuration--error',
  }),
  HL7_MESSAGES_SEARCH_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.search-messages'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-messages-search--error',
  }),
  HL7_MESSAGE_GET_FAILED: () => ({
    type: 'error',
    message: i18n.t('hl7-configuration.errors.get-message'),
    description: i18n.t('hl7-configuration.errors--description'),
    duration: 5,
    datatestid: 'hl7-message-get--error',
  }),
  REPORTS_MESSAGE_RUN_FAILED: () => ({
    type: 'error',
    message: i18n.t('reports.errors.run-report'),
    description: i18n.t('reports.errors.run-report--description'),
    duration: 5,
    datatestid: 'reports-message-run--error',
  }),
  DASHBOARD_LOAD_FAILED: () => ({
    type: 'error',
    message: i18n.t('dashboard.errors.load-dashboard'),
    description: i18n.t('dashboard.errors.load-dashboard--description'),
    duration: 5,
    datatestid: 'dashboard-load--error',
  }),
};
