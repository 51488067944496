// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect } from 'react';
import { Badge, Empty, Skeleton, Table, Tag } from 'antd';
import moment from 'moment';
import { PatientDecrypted } from 'src/api/types';
import type { TableProps } from 'antd';
import classes from './PatientActivityHistory.module.less';
import { useTranslation } from 'react-i18next';
import { usePatientSearch } from '../../../patient-search/store/hooks';
import { useDispatch } from 'react-redux';
import { patientActivityHistory } from '../../../patient-search/store/facades';
import { FilterFilled } from '@ant-design/icons';

interface Activity {
  key: string;
  date: string;
  activity: string;
  result: string;
  machine: string;
  user: string;
}

interface ActivityHistoryTableProps {
  patient: PatientDecrypted | null;
}

const PatientActivityHistoryTable: React.FC<ActivityHistoryTableProps> = ({
  patient,
}) => {
  const { t } = useTranslation();
  const { isLoadingActivityHistory, patientActivities } = usePatientSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (patient) {
      patientActivityHistory(patient.id ?? '', dispatch);
    }
  }, []);

  const data: Activity[] =
    patientActivities?.activities?.map((activity, index) => ({
      key: index.toString(),
      date: moment(activity.date)?.format('YYYY-MM-DD HH:mm:ss') ?? '',
      activity: activity.activityType ?? '',
      result: activity.activitySubtype ?? '',
      machine: (activity.createdAt && activity.createdAt !== '') ? activity.createdAt : 'N/A',
      user: (activity.createdBy && activity.createdBy !== '') ? activity.createdBy : 'N/A',
    })) || [];

  

  const columns: TableProps<Activity>['columns'] = [
    {
      title: () => (
        <span data-testid="patient-activity-history-table--sortable-column--header-date">
          Date
        </span>
      ),
      dataIndex: 'date',
      key: 'date',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      sorter: {
        compare: (a1, a2) => new Date(a1.date).getTime() - new Date(a2.date).getTime(),
        multiple: 3,
      },
      defaultSortOrder: 'descend',
      render: (_, record: Activity) => {
        return (
          <span data-testid={`patient-activity-history--date-${record.key}`}>
            {moment(record.date)?.format('YYYY-MM-DD HH:mm') ?? ''}
          </span>
        )
      },
    },
    {
      title: () => (
        <span data-testid="patient-activity-history-table--sortable-column--header-activity">
          Activity
        </span>
      ),
      dataIndex: 'activity',
      key: 'activity',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      sorter: {
        compare: (a1, a2) => a1.activity.localeCompare(a2.activity, undefined, { sensitivity: 'base' }),
        multiple: 2,
      },
      render: (_, record: Activity) => {
        let styleClass: string;
        switch (record.activity) {
          case 'Enrollment':
            styleClass = classes.enrollmentTag;
            break;
          case 'Verification':
            styleClass = classes.verificationTag;
            break;
          case 'Identification':
            styleClass = classes.identificationTag;
            break;
          default:
            styleClass = classes.defaultTag;
        }
        return (
          <Tag data-testid={`patient-activity-history--activity-${record.key}`} className={`${styleClass} ${classes.activityTag}`}>
            {record.activity}
          </Tag>
        );
      },
    },
    {
      title: () => (
        <span data-testid="patient-activity-history-table--sortable-column--header-result">
          Result
        </span>
      ),
      dataIndex: 'result',
      key: 'result',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      sorter: {
        compare: (a1, a2) => a1.result.localeCompare(a2.result, undefined, { sensitivity: 'base' }),
        multiple: 1,
      },
      render: (_, record: Activity) => {
        let styleClass: string;
        let result_value: string = record.result;
        switch (record.result) {
          case 'Success':
            styleClass = classes.successBadge;
            break;
          case 'Failed':
            styleClass = classes.failedBadge;
            break;
          case 'MultipleMatchesSuccess':
            result_value =
            record.result.charAt(0) +
            record.result
                .slice(1)
                .replace(/([A-Z])/g, match => ` ${match.toLowerCase()}`);
            styleClass = classes.multipleMatchesBadge;
            break;
          default:
            styleClass = classes.defaultBadge;
        }
        return (
          <Badge className={`${styleClass} ${classes.dotBadge}`}>
            <span data-testid={`patient-activity-history--result-${record.key}`} className={classes.textBadge}>{result_value}</span>
          </Badge>
        );
      },
      ellipsis: true,
    },
    {
      title: 'Machine',
      dataIndex: 'machine',
      key: 'machine',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filters: data.filter((currentOption, index, self) => 
                      index === self.findIndex((dataOption) => dataOption.machine === currentOption.machine))
                    .map(activity => ({ text: activity.machine, value: activity.machine })),
      filterSearch: true,
      filterIcon: (filtered) => <FilterFilled data-testid='patient-activity-history--machine-filter' className={ filtered ? classes.filterSelected : classes.filterUnselected } />,
      onFilter: (value, record) => record.machine === value,
      render: (_, record: Activity) => {
        return (
          <span data-testid={`patient-activity-history--machine-${record.key}`}>
            {record.machine}
          </span>
        )
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      onHeaderCell: () => ({
        className: classes.tableHeader,
      }),
      filters: data.filter((currentOption, index, self) => 
                      index === self.findIndex((dataOption) => dataOption.user === currentOption.user))
                    .map(activity => ({ text: activity.user, value: activity.user })),
      filterSearch: true,
      filterIcon: (filtered) => <FilterFilled data-testid='patient-activity-history--user-filter' className={ filtered ? classes.filterSelected : classes.filterUnselected } />,
      onFilter: (value, record) => record.user === value,
      render: (_, record: Activity) => {
        return (
          <span data-testid={`patient-activity-history--user-${record.key}`}>
            {record.user}
          </span>
        )
      },
    },
  ];


  return isLoadingActivityHistory ? <Skeleton active /> : (
    <Table
      data-testid="patient-activity-history--table"
      columns={columns}
      dataSource={data}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('patient-details.no-activity-history')}
          />
        ),
      }}
      tableLayout="fixed"
    />
  );
};

export default PatientActivityHistoryTable;
