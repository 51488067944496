// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Layout, Spin } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import {
  DASHBOARD_ROUTE,
  HELP_ROUTE,
  INSTALLERS_ROUTE,
  PATIENT_SEARCH_ROUTE,
  PATIENT_DETAILS_ROUTE,
  INTEGRATIONS_ROUTE,
  REPORTS_ROUTE,
  SETTINGS_ROUTE,
} from '../../routers/route-names';
import Dashboard from '../dashboard/Dashboard';

import classes from '../../styles/layout.module.less';
import SiderMenu from '../../components/sider-menu/SiderMenu';
import ProtectedRoute from '../../components/protected-route/ProtectedRoute';
import { useSiteLayoutNavigation, getActiveItemFromPath } from './store/hooks';
import PatientSearch from '../patient-search/PatientSearch';
import EndpointInstaller from '../installer/EndpointInstaller';
import Integrations from '../integrations/Integrations';
import Help from '../help/Help';
import PatientDetailsContainer from '../patient-details/PatientDetailsContainer';
import Reports from '../reports/Reports';
import Settings from '../settings/Settings';

const siderMenuProps = {
  siderProps: { collapsed: true, 'data-testid': 'app-sider' },
  menuProps: {
    selectable: true,
    disabled: false,
    'data-testid': 'app-sider-menu',
  },
};

const SiteLayout: React.FC = () => {
  const [activeMenuItem, setActiveMenuItem, handleItemClick] =
    useSiteLayoutNavigation();

  const onMenuClick = useCallback(
    (menuInfo: { key: string }) => handleItemClick(menuInfo.key),
    [handleItemClick],
  );

  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP' || history.action === 'PUSH') {
        setActiveMenuItem(
          getActiveItemFromPath(location.pathname) || DASHBOARD_ROUTE,
        );
      }
    });
  }, [activeMenuItem, setActiveMenuItem, history, onMenuClick]);

  return (
    <Spin spinning={false}>
      <Layout className={classes.mainContainer} data-testid="site-layout">
        <SiderMenu
          siderProps={siderMenuProps.siderProps}
          menuProps={{
            ...siderMenuProps.menuProps,
            selectedKeys: [activeMenuItem],
            onClick: onMenuClick,
          }}
        />
        <Switch>
          <ProtectedRoute exact path={DASHBOARD_ROUTE} component={Dashboard} />
          <ProtectedRoute
            path={PATIENT_SEARCH_ROUTE}
            component={PatientSearch}
          />
          <ProtectedRoute path={REPORTS_ROUTE} component={Reports} />
          <ProtectedRoute
            path={INSTALLERS_ROUTE}
            component={EndpointInstaller}
          />
          <ProtectedRoute path={INTEGRATIONS_ROUTE} component={Integrations} />
          <ProtectedRoute path={SETTINGS_ROUTE} component={Settings} />
          <ProtectedRoute path={HELP_ROUTE} component={Help} />
          <ProtectedRoute
            path={PATIENT_DETAILS_ROUTE}
            component={PatientDetailsContainer}
          />
        </Switch>
      </Layout>
    </Spin>
  );
};

export default SiteLayout;
