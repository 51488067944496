// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Tabs } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { PatientDecrypted } from 'src/api/types';
import PatientDemographicUpdatesTable from './PatientDemographicUpdatesTable';
import classes from './PatientDemographics.module.less';
import PatientActivityHistoryTable from '../patient-activity-history/PatientActivityHistoryTable';

export interface PatientDetailsTabsPanelProps {
  patient: PatientDecrypted | null;
}

const PatientDetailsTabsPanel: React.FC<PatientDetailsTabsPanelProps> = ({
  patient,
}) => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const { t } = useTranslation();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: '1',
      label: (
        <span data-testid="patient-tab-activity">
          {t('patient-details.navigation-tab-options.activities')}
        </span>
      ),
      children: <PatientActivityHistoryTable patient={patient} />,
    },
    {
      key: '2',
      label: (
        <span data-testid="patient-tab-demographic-updates">
          {t('patient-details.navigation-tab-options.demographic-history')}
        </span>
      ),
      children: <PatientDemographicUpdatesTable patient={patient} />,
    },
  ];

  return (
    <Tabs
      className={classes.patientTabsPanel}
      activeKey={activeTab}
      onChange={handleTabChange}
      items={items}
      data-testid="patient-details-tabs-panel"
    />
  );
};

export default PatientDetailsTabsPanel;
