// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Banner, destroyBanners } from '@imprivata-cloud/components';
import { AuthnModule, EventType } from '@imprivata-cloud/authn';
import shortUUID from 'short-uuid';
import { DASHBOARD_ROUTE } from '../../routers/route-names';
import history from '../../routers/history';
import { errorMapping } from '../../utils/BannerConstants';
import {
  stateSaveUsernameAction,
  startClientUserSessionAction,
  getTenantTypeAction,
} from './store/actions';
import { useLoginHook } from './store/hooks';
import SetTitle from '../../utils/DynamicTitleHelper';
import { tracer, workflowId } from '../../tracing';
import { getPathWithQuery } from '../../utils/routingHelpers';
import { setCodingContext } from '../../api/client';
import { saveCodingContext } from './utils';

const LoginContainer: React.FC = () => {
  const [paramsAdded, setParamsAdded] = useState(true);
  const { t } = useTranslation();

  SetTitle(t('login.ps-title'));

  useEffect(() => {
    const paramsBefore = new URLSearchParams(window.location.search);
    paramsBefore.set('contextType', 'generic-app-login');
    paramsBefore.set('resourceType', 'admin-web-ui');
    paramsBefore.set('workflowId', shortUUID.uuid());
    // paramsBefore.set('cipherV2', 'true');
    history.push({ search: paramsBefore.toString() });

    setParamsAdded(true);

    return () => {
      const paramsAfter = new URLSearchParams(window.location.search);
      paramsAfter.delete('contextType');
      paramsAfter.delete('resourceType');
      paramsAfter.delete('workflowId');
      // paramsAfter.delete('cipherV2');
      history.push({ search: paramsAfter.toString() });
    };
  }, []);

  const {
    permissionError,
    username,
    storedURL,
    hasValidSession,
    errorToDisplay,
  } = useLoginHook();

  const dispatch = useDispatch();

  useEffect(() => {
    if (permissionError) {
      Banner({
        type: 'error',
        message: t('errors.UXID_ADMIN_NOTALLOWED', { username }),
        duration: 10,
        datatestid: 'permission-error',
      });
    }
  }, [permissionError, username, dispatch, t]);

  useEffect(() => {
    const destroy = () => {
      destroyBanners();
    };
    if (errorToDisplay && !errorToDisplay.includes('UXID_MULTIPLEUSERS')) {
      const errorType = errorMapping[errorToDisplay as never];
      Banner({
        type: errorType || 'error',
        message: t(errorToDisplay as never),
        duration: 10,
        datatestid: 'error-message',
      });
    } else {
      destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToDisplay, dispatch]);

  const loggedInLandingRoute = getPathWithQuery(DASHBOARD_ROUTE);

  const location = useLocation();
  const tenantId = new URLSearchParams(location.search).get('tenantId');

  const authnKey = useMemo(() => permissionError, [permissionError]);

  return (
    <>
      {hasValidSession && <Redirect to={storedURL ?? loggedInLandingRoute} />}
      {(paramsAdded && tenantId && (
        <AuthnModule
          key={authnKey}
          tenantId={tenantId}
          onEvent={({ event, data }) => {
            switch (event) {
              case EventType.AUTHENTICATED:
                if (data?.codingContext) {
                  saveCodingContext(
                    data.codingContext.buildImprCodingCtxHeader(),
                  );
                  setCodingContext(data.codingContext);
                }
                if (data?.username) {
                  dispatch(stateSaveUsernameAction(data.username));
                }
                dispatch(startClientUserSessionAction.request({ tenantId }));
                dispatch(getTenantTypeAction.request());
                break;
            }
          }}
          requestConfig={{
            clientName: 'Ps-Admin-Ui',
          }}
          tracing={{
            workflowId,
            tracer,
          }}
        />
      )) || <></>}
    </>
  );
};

export default LoginContainer;
