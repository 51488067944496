// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  tracer,
  SpanNames,
  recordException,
  startRootSpan,
  endRootSpan,
} from '../../tracing';

export function startGetMinimumAgeConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_MINIMUM_AGE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetMinimumAgeConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_MINIMUM_AGE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_MINIMUM_AGE_CONFIGURATION);
  endRootSpan();
}

export function startSaveMinimumAgeConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SAVE_MINIMUM_AGE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endSaveMinimumAgeConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SAVE_MINIMUM_AGE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SAVE_MINIMUM_AGE_CONFIGURATION);
  endRootSpan();
}

export function startClearMinimumAgeConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.CLEAR_MINIMUM_AGE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endClearMinimumAgeConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.CLEAR_MINIMUM_AGE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.CLEAR_MINIMUM_AGE_CONFIGURATION);
  endRootSpan();
}
