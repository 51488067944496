// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Menu, Layout } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import MenuDashboard from '../../assets/svg/menu-dashboard.svg?react';
import MenuPatients from '../../assets/svg/menu-patient-search.svg?react';
import MenuReports from '../../assets/svg/menu-reports.svg?react';
import MenuInstallers from '../../assets/svg/menu-endpoint-installer.svg?react';
import MenuIntegrations from '../../assets/svg/menu-integrations.svg?react';
import MenuSettings from '../../assets/svg/menu-settings.svg?react';
import MenuHelp from '../../assets/svg/menu-help.svg?react';

import classes from './SiderMenu.module.less';

const { Sider } = Layout;

interface SiderMenuProps {
  siderProps?: React.ComponentProps<typeof Sider>;
  menuProps?: React.ComponentProps<typeof Menu>;
}

export enum MenuItemKeys {
  Dashboard = 'dashboard',
  Patients = 'patients',
  Reports = 'reports',
  Installers = 'installers',
  Integrations = 'integrations',
  Settings = 'settings',
  Logout = 'logout',
  Help = 'help',
}

const SiderMenu: React.FC<SiderMenuProps> = function SiderMenu({
  siderProps = {},
  menuProps = {},
}) {
  const { t } = useTranslation();
  const { collapsedWidth = 150, ...restSiderProps } = siderProps;
  const { mode, className: menuClassName, ...restMenuProps } = menuProps;

  const items: MenuProps['items'] = [
    {
      type: 'group',
      className: classes.topGroup,
      children: [
        {
          className: classes.menuItem,
          key: MenuItemKeys.Dashboard,
          icon: <Icon component={MenuDashboard} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--dashboard"
            >
              {t('navigation.dashboard')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Patients,
          icon: <Icon component={MenuPatients} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--patients"
            >
              {t('navigation.patients')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Reports,
          icon: <Icon component={MenuReports} />,
          label: (
            <span className="nav-label" data-testid="side-menu-button--reports">
              {t('navigation.reports')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Installers,
          icon: <Icon component={MenuInstallers} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--installers"
            >
              {t('navigation.installers')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Integrations,
          icon: <Icon component={MenuIntegrations} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--integrations"
            >
              {t('navigation.integrations')}
            </span>
          ),
        },
        {
          className: classes.menuItem,
          key: MenuItemKeys.Settings,
          icon: <Icon component={MenuSettings} />,
          label: (
            <span
              className="nav-label"
              data-testid="side-menu-button--settings"
            >
              {t('navigation.settings')}
            </span>
          ),
        },
      ],
    },
    {
      type: 'group',
      className: classes.midGroup,
      children: [
        {
          className: classes.menuItem,
          key: MenuItemKeys.Help,
          icon: <Icon component={MenuHelp} />,
          label: (
            <span className="nav-label" data-testid="side-menu-button--help">
              {t('navigation.help')}
            </span>
          ),
        },
      ],
    },
    {
      type: 'group',
      className: classes.bottomGroup,
      children: [
        {
          className: classes.menuItem,
          key: MenuItemKeys.Logout,
          label: (
            <span className="nav-label" data-testid="side-menu-button--logout">
              {t('navigation.logout')}
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <Sider
      theme="dark"
      collapsed
      collapsedWidth={collapsedWidth}
      collapsible={false}
      data-testid="side-menu"
      {...restSiderProps}
    >
      <Menu
        theme="dark"
        mode="inline"
        className={clsx(classes.menu, menuClassName)}
        {...restMenuProps}
        items={items}
      />
    </Sider>
  );
};

export default SiderMenu;
