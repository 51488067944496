// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap, catchError, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';
import {
  fetchDemographicsHistoryActions,
  patientActivityHistoryActions,
  patientSearchActions,
  selectPatientAction,
} from './actions';
import {
  getDemographicsHistory$,
  getActivityHistory$,
  searchPatient$,
} from '../../../api/services/patientService';
import {
  endGetDemographicHistorySpan,
  endSearchForPatientSpan,
  startGetDemographicHistorySpan,
  startSearchForPatientSpan,
  endGetActivityHistorySpan,
  startGetActivityHistorySpan,
} from '../tracing';
import { showErrorBannerAction } from '../../../store/error-banner-state/actions';
import { errors } from '../../../store/error-banner-state/errors';

export const patientSearchEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(patientSearchActions.request)),
    tap(() => {
      startSearchForPatientSpan();
    }),
    switchMap(({ payload }) => {
      return searchPatient$(payload).pipe(
        tap(() => {
          endSearchForPatientSpan();
        }),
        map(response => patientSearchActions.success(response)),
        catchError(error => {
          endSearchForPatientSpan(error);
          return of(
            patientSearchActions.failure({
              code: getErrorMessageCode(
                ContextNames.PATIENT_SEARCH,
                'patient-search-failed',
              ),
              message:
                'An error occurred while searching for the patient. Please try again.',
            }),
            showErrorBannerAction.request(errors.PATIENT_SEARCH_FAILED()),
          );
        }),
      );
    }),
  );

export const fetchDemographicsHistoryEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(fetchDemographicsHistoryActions.request)),
    tap(() => {
      startGetDemographicHistorySpan();
    }),
    switchMap(({ payload }) => {
      return getDemographicsHistory$(payload as string).pipe(
        tap(() => {
          endGetDemographicHistorySpan();
        }),
        map(response => fetchDemographicsHistoryActions.success(response)),
        catchError(error => {
          endGetDemographicHistorySpan(error);
          return of(
            fetchDemographicsHistoryActions.failure({
              code: getErrorMessageCode(
                ContextNames.PATIENT_SEARCH,
                'demographic-history-failed',
              ),
              message:
                'An error occurred while fetching demographic history. Please try again.',
            }),
            showErrorBannerAction.request(errors.DEMOGRAPHIC_HISTORY_FAILED()),
          );
        }),
      );
    }),
  );

export const patientActivityHistoryEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(patientActivityHistoryActions.request)),
    tap(() => {
      startGetActivityHistorySpan();
    }),
    switchMap(({ payload }) => {
      return getActivityHistory$(payload).pipe(
        tap(() => {
          endGetActivityHistorySpan();
        }),
        map(payload => patientActivityHistoryActions.success(payload)),
        catchError(error => {
          endGetActivityHistorySpan(error);
          return of(
            patientActivityHistoryActions.failure({
              code: getErrorMessageCode(
                ContextNames.PATIENT_ACTIVITY_HISTORY,
                'patient-activity-history-failed',
              ),
              message:
                'An error occurred while getting the activity history of the patient. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.PATIENT_ACTIVITY_HISTORY_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const patientSearchEpics = combineEpics(
  patientSearchEpic,
  patientActivityHistoryEpic,
  fetchDemographicsHistoryEpic,
);
