// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Spin } from 'antd';
import { InputBox } from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import PageSubHeader from '../../../../components/page-sub-header/PageSubHeader';
import CancelButton from '../../../../components/action-bar/CancelButton';
import HelpButton from '../../../../components/action-bar/HelpButton';
import SaveButton from '../../../../components/action-bar/SaveButton';
import SaveDiscardModal from '../../../../components/save-discard-modal/SaveDiscardModal';
import { useGetMinimumAgeConfiguration } from './store/hooks';
import classes from './AgeLimitConfigurationContainer.module.less';
import {
  clearMinimumAgeConfiguration,
  saveMinimumAgeConfiguration,
} from './store/facades';
import SetTitle from '../../../../utils/DynamicTitleHelper';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const setAgeLimitsFormData = (minimumAge: number | undefined) => {
  return {
    minimumAge,
  };
};

const AgeLimitConfigurationComponent: React.FC = () => {
  const { t } = useTranslation();
  const [ageLimitsform] = Form.useForm();
  const dispatch = useDispatch();
  SetTitle(t('age-limit-configuration.title'));

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [reset, setReset] = useState(false);

  const { minimumAgeConfiguration, minimumAgeRangeConstants, isLoading } =
    useGetMinimumAgeConfiguration();

  const validateAndUpdate = () => {
    const newMinimumAge = ageLimitsform.getFieldValue('minimumAge') || '';

    setIsDirty(false);
    // prevent saving a minimum age that is not a integer or outside the range through modal
    if (
      !hasMinimumAgeChanged() ||
      (newMinimumAge !== '' &&
        (!Number.isInteger(Number(newMinimumAge)) ||
          (minimumAgeRangeConstants?.maximumAgeRange &&
            minimumAgeRangeConstants?.minimumAgeRange &&
            (newMinimumAge < minimumAgeRangeConstants.minimumAgeRange ||
              newMinimumAge > minimumAgeRangeConstants.maximumAgeRange))))
    ) {
      return;
    }
    ageLimitsform.setFieldsValue({
      minimumAge: newMinimumAge,
    });

    ageLimitsform.validateFields().then(() => {
      if (newMinimumAge === '') {
        clearMinimumAgeConfiguration(
          minimumAgeConfiguration?.settingId ?? '',
          dispatch,
        );
      } else {
        saveMinimumAgeConfiguration(
          minimumAgeConfiguration?.settingId ?? '',
          newMinimumAge,
          dispatch,
        );
      }

      setIsSaveDisabled(true);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      ageLimitsform.setFieldsValue(
        setAgeLimitsFormData(minimumAgeConfiguration?.minimumAge),
      );
      setReset(!reset);
    }

    setIsSaveDisabled(true);
    setIsDirty(false);
  };

  const handleChange = () => {
    setIsSaveDisabled(false);

    ageLimitsform.validateFields().catch(() => {
      setIsSaveDisabled(true);
    });

    if (!hasMinimumAgeChanged()) {
      setIsDirty(false);
      setIsSaveDisabled(true);
    } else {
      setIsDirty(true);
    }
  };

  const hasMinimumAgeChanged = () =>
    ageLimitsform.getFieldValue('minimumAge') !==
    (minimumAgeConfiguration?.minimumAge.toString() ?? '');

  useEffect(() => {
    ageLimitsform.setFieldsValue(
      setAgeLimitsFormData(minimumAgeConfiguration?.minimumAge),
    );
  }, [minimumAgeConfiguration, ageLimitsform]);

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <PageSubHeader
        title={
          <>
            <SaveButton
              onClick={() => validateAndUpdate()}
              disabled={isSaveDisabled}
            />
            <CancelButton onClick={() => resetForm()} disabled={!isDirty} />
            <SaveDiscardModal
              title={t('age-limit-configuration.save-discard-modal.title')}
              cancelText={t(
                'age-limit-configuration.save-discard-modal.discard',
              )}
              okText={t('actions.save')}
              content={t('age-limit-configuration.save-discard-modal.content')}
              open={isDirty}
              onSave={() => validateAndUpdate()}
              onDiscard={() => resetForm()}
            ></SaveDiscardModal>
          </>
        }
        extra={<HelpButton />}
      />
      <Form
        {...formItemLayout}
        form={ageLimitsform}
        layout="vertical"
        name="register"
        onChange={handleChange}
        className={classes.ageLimitConfigurationForm}
      >
        <div>
          <Row gutter={10} wrap={true} className={classes.rowWithTopSpacing}>
            <Col>
              <div
                data-testid="age-limit-configuration-form--header"
                className="h3-header configuration-header"
              >
                {t('age-limit-configuration.title')}
              </div>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="age-limit-configuration-form--minimum-age-label"
                name="minimumAge"
                label={t('age-limit-configuration.minimum-age', {
                  default: minimumAgeRangeConstants?.defaultMinimumAge,
                })}
                rules={[
                  {
                    type: 'number',
                    validator: async (_, value) => {
                      // must be an integer between minimum and maximum age
                      if (
                        minimumAgeRangeConstants?.maximumAgeRange &&
                        minimumAgeRangeConstants?.minimumAgeRange &&
                        value !== '' &&
                        (!Number.isInteger(Number(value)) ||
                          value < minimumAgeRangeConstants.minimumAgeRange ||
                          value > minimumAgeRangeConstants.maximumAgeRange)
                      ) {
                        return Promise.reject(
                          new Error(
                            t('age-limit-configuration.minimum-age-range', {
                              min: minimumAgeRangeConstants.minimumAgeRange,
                              max: minimumAgeRangeConstants.maximumAgeRange,
                            }),
                          ),
                        );
                      }
                    },
                  },
                ]}
              >
                <InputBox
                  data-testid="age-limit-configuration--minimum-age"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default AgeLimitConfigurationComponent;
