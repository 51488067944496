// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { RootAction } from '../../../../../store/rootAction';
import {
  minimumAgeConfigurationClearActions,
  minimumAgeConfigurationGetActions,
  minimumAgeConfigurationSaveActions,
} from './actions';
import { invalidSessionAction } from '../../../../login/store/actions';
import { GetMinimumAgeResponse } from '@imprivata-cloud/adminapi-client';
import { MinimumAgeConfiguration, MinimumAgeRangeConstants } from '../types';

export interface AgeLimitConfigurationState {
  loading: boolean;
  error: string | null;
  minimumAgeConfiguration: MinimumAgeConfiguration | null;
  minimumAgeConstants: MinimumAgeRangeConstants | null;
}

export const initialState: AgeLimitConfigurationState = {
  loading: false,
  error: null,
  minimumAgeConfiguration: null,
  minimumAgeConstants: null,
};

export const ageLimitConfigurationReducer =
  combineReducers<AgeLimitConfigurationState>({
    loading: createReducer<boolean>(initialState.loading)
      .handleAction(
        [
          minimumAgeConfigurationGetActions.request,
          minimumAgeConfigurationSaveActions.request,
          minimumAgeConfigurationClearActions.request,
        ],
        () => true,
      )
      .handleAction(
        [
          minimumAgeConfigurationGetActions.cancel,
          minimumAgeConfigurationGetActions.success,
          minimumAgeConfigurationGetActions.failure,
          minimumAgeConfigurationSaveActions.cancel,
          minimumAgeConfigurationSaveActions.success,
          minimumAgeConfigurationSaveActions.failure,
          minimumAgeConfigurationClearActions.cancel,
          minimumAgeConfigurationClearActions.success,
          minimumAgeConfigurationClearActions.failure,
          invalidSessionAction.request,
        ],
        () => false,
      ),
    minimumAgeConfiguration: createReducer<MinimumAgeConfiguration | null>(
      initialState.minimumAgeConfiguration,
    )
      .handleAction(
        [minimumAgeConfigurationGetActions.success],
        (_, { payload }: { payload: GetMinimumAgeResponse }) => {
          return payload.current
            ? {
                minimumAge: payload.current.minimumAge,
                settingId: payload.current.settingId,
              }
            : null;
        },
      )
      .handleAction(
        [minimumAgeConfigurationSaveActions.request],
        (_, { payload }) => {
          return {
            minimumAge: payload.newMinimumAge,
            settingId: payload.settingId,
          };
        },
      )
      .handleAction([minimumAgeConfigurationClearActions.success], () => null)
      .handleAction([invalidSessionAction.request], () => null),
    minimumAgeConstants: createReducer<MinimumAgeRangeConstants | null>(
      initialState.minimumAgeConstants,
    )
      .handleAction(
        [minimumAgeConfigurationGetActions.success],
        (_, { payload }: { payload: GetMinimumAgeResponse }) => {
          return {
            defaultMinimumAge: payload.defaultMinimumAge,
            minimumAgeRange: payload.minimumAgeRange,
            maximumAgeRange: payload.maximumAgeRange,
          };
        },
      )
      .handleAction([invalidSessionAction.request], () => null),
    error: createReducer<string | null, RootAction>(initialState.error)
      .handleAction(
        [
          minimumAgeConfigurationGetActions.request,
          minimumAgeConfigurationGetActions.success,
          minimumAgeConfigurationSaveActions.request,
          minimumAgeConfigurationSaveActions.success,
          invalidSessionAction.request,
        ],
        () => null,
      )
      .handleAction(
        [
          minimumAgeConfigurationGetActions.failure,
          minimumAgeConfigurationSaveActions.failure,
        ],
        (_, { payload }) => payload.code || null,
      )
      .handleAction(
        [
          minimumAgeConfigurationGetActions.cancel,
          minimumAgeConfigurationSaveActions.cancel,
        ],
        () => 'cancelled',
      ),
  });
