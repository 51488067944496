// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Spin } from 'antd';
import type {
  Hl7RuleConfiguration,
  SaveHl7RuleConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import { Hl7RuleType } from '@imprivata-cloud/adminapi-client';
import { InputBox, destroyBanners } from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'antd/lib/select';
import type { DefaultOptionType } from 'antd/lib/select';
import PageSubHeader from '../../../../../../components/page-sub-header/PageSubHeader';
import CancelButton from '../../../../../../components/action-bar/CancelButton';
import HelpButton from '../../../../../../components/action-bar/HelpButton';
import SaveButton from '../../../../../../components/action-bar/SaveButton';
import SaveDiscardModal from '../../../../../../components/save-discard-modal/SaveDiscardModal';
import classes from './Hl7RuleConfigurationContainer.module.less';
import { saveHl7RuleConfiguration } from '../../store/facades';
import { getHl7RuleConfigurationsById$ } from '../../../../../../api/services/hl7ConfigurationService';
import { ruleTypeToString } from '../../utils/RuleTypeUtils';
import {
  endGetHl7RuleConfigurationSpan,
  startGetHl7RuleConfigurationSpan,
} from '../../../../tracing';
import { showErrorBannerAction } from '../../../../../../store/error-banner-state/actions';
import { errors } from '../../../../../../store/error-banner-state/errors';

let isDirty: boolean;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const hl7RuleTypeOptions: DefaultOptionType[] = [
  {
    value: Hl7RuleType.PhotoAdded,
    label: ruleTypeToString(Hl7RuleType.PhotoAdded),
  },
];

const requiredMsgKey = 'hl7-configuration.required';

const setConfigurationFormData = (formData: Hl7RuleConfiguration | null) => {
  const ruleIdentifierMappings = formData?.ruleIdentifierMappings?.length
    ? formData?.ruleIdentifierMappings[0]
    : null;
  return {
    displayNameLabel: formData?.displayName,
    ruleTypeLabel: formData?.ruleType ?? Hl7RuleType.PhotoAdded,
    fhirSystemOidLabel: ruleIdentifierMappings?.fhirSystemOid,
    hl7AssigningAuthorityLabel: ruleIdentifierMappings?.hl7AssigningAuthority,
    hl7CodingSystemLabel: ruleIdentifierMappings?.hl7CodingSystem,
  };
};

const Hl7RuleConfigurationComponent: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);

  const [ruleConfiguration, setRuleConfiguration] =
    useState<Hl7RuleConfiguration | null>(null);

  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [isSaveChangesModalVisible, setIsSaveChangesModalVisible] =
    useState<boolean>(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const validateAndUpdate = () => {
    isDirty = false;

    const displayNameValue = form.getFieldValue('displayNameLabel') || '';
    const ruleTypeValue: Hl7RuleType =
      form.getFieldValue('ruleTypeLabel') || Hl7RuleType.PhotoAdded;
    const fhirSystemOidValue = form.getFieldValue('fhirSystemOidLabel') || '';
    const hl7AssigningAuthorityValue =
      form.getFieldValue('hl7AssigningAuthorityLabel') || '';
    const hl7CodingSystemValue =
      form.getFieldValue('hl7CodingSystemLabel') || '';

    form.setFieldsValue({
      displayNameLabel: displayNameValue,
      ruleTypeLabel: ruleTypeValue,
      fhirSystemOidLabel: fhirSystemOidValue,
      hl7AssigningAuthorityLabel: hl7AssigningAuthorityValue,
      hl7CodingSystemLabel: hl7CodingSystemValue,
    });

    form.validateFields().then(() => {
      const request: SaveHl7RuleConfigurationRequest = {
        id: ruleConfiguration?.id,
        displayName: displayNameValue,
        ruleType: ruleTypeValue,
        ruleIdentifierMappings: [
          {
            fhirSystemOid: fhirSystemOidValue,
            hl7AssigningAuthority: hl7AssigningAuthorityValue,
            hl7CodingSystem: hl7CodingSystemValue,
          },
        ],
      };
      saveHl7RuleConfiguration(request, dispatch);

      setSaveDisabled(true);
      setIsSaveChangesModalVisible(false);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      form.setFieldsValue(setConfigurationFormData(ruleConfiguration));
      setReset(!reset);
    }

    setSaveDisabled(true);
    isDirty = false;
    setIsSaveChangesModalVisible(false);
  };

  const handleChange = () => {
    setSaveDisabled(false);

    form.validateFields().catch(() => {
      setSaveDisabled(true);
    });

    isDirty = true;
  };

  useEffect(() => {
    return () => {
      if (isDirty) {
        setIsSaveChangesModalVisible(true);
      }
    };
  });

  useEffect(() => {
    form.setFieldsValue(setConfigurationFormData(ruleConfiguration));
  }, [ruleConfiguration, form]);

  const useGetRuleConfigurationEffect = () =>
    useEffect(() => {
      setIsLoading(true);
      const destroy = () => destroyBanners();
      if (!ruleConfiguration) {
        const ruleId = currentSearchParams.get('ruleId');
        if (ruleId) {
          startGetHl7RuleConfigurationSpan();
          getHl7RuleConfigurationsById$(ruleId)
            .toPromise()
            .then(resultConfiguration => {
              endGetHl7RuleConfigurationSpan();
              const config = resultConfiguration?.length
                ? resultConfiguration[0]
                : null;
              setRuleConfiguration(config);
              form.setFieldsValue(setConfigurationFormData(config));
              destroy();
            })
            .catch(err => {
              endGetHl7RuleConfigurationSpan(err);
              dispatch(
                showErrorBannerAction.request(errors.HL7_RULE_GET_FAILED()),
              );
            });
        }
      }
      setIsLoading(false);
    }, []);
  useGetRuleConfigurationEffect();

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <PageSubHeader
        title={
          <>
            <SaveButton
              onClick={() => validateAndUpdate()}
              disabled={isSaveDisabled}
            />
            <CancelButton onClick={() => resetForm()} disabled={!isDirty} />
            <SaveDiscardModal
              title={t('hl7-configuration.save-discard-modal.title')}
              cancelText={t('hl7-configuration.save-discard-modal.discard')}
              okText={t('actions.save')}
              content={t('hl7-configuration.save-discard-modal.content')}
              open={isSaveChangesModalVisible}
              onSave={() => validateAndUpdate()}
              onDiscard={() => resetForm()}
            ></SaveDiscardModal>
          </>
        }
        extra={
          <>
            <HelpButton />
          </>
        }
      />
      <Form
        {...formItemLayout}
        form={form}
        layout="vertical"
        name="register"
        onChange={handleChange}
        className={classes.hl7RuleConfigurationForm}
      >
        <div>
          <Row gutter={10} wrap={true} className={classes.rowWithTopSpacing}>
            <Col>
              <div
                data-testid="hl7-configuration-form--header"
                className="h3-header configuration-header"
              >
                {t('hl7-configuration.rule-configuration.title')}
              </div>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="hl7-configuration-form--displayName-label"
                name="displayNameLabel"
                label={t('hl7-configuration.rule-configuration.display-name')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="hl7-configuration--displayName"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="hl7-configuration-form--rule-type-label"
                name="ruleTypeLabel"
                className="impr-select-wrapper"
                initialValue={Hl7RuleType.PhotoAdded}
                label={t('hl7-configuration.rule-configuration.rule-type')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <Select
                  data-testid="hl7-configuration--rule-type"
                  options={hl7RuleTypeOptions}
                  className="impr-select-container"
                  disabled={hl7RuleTypeOptions.length <= 1}
                  style={{ width: '100% !important' }}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="hl7-configuration-form--fhirSystemOid-label"
                name="fhirSystemOidLabel"
                label={t(
                  'hl7-configuration.rule-configuration.fhir-system-oid',
                )}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="hl7-configuration--fhirSystemOid"
                  className={classes.input}
                  placeholder="urn:oid:1.1.111"
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="hl7-configuration-form--hl7-assigning-authority-label"
                name="hl7AssigningAuthorityLabel"
                label={t(
                  'hl7-configuration.rule-configuration.hl7-assigning-authority',
                )}
              >
                <InputBox
                  data-testid="hl7-configuration--hl7-assigning-authority"
                  className={classes.input}
                  placeholder="MRN"
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="hl7-configuration-form--hl7-coding-system-label"
                name="hl7CodingSystemLabel"
                label={t(
                  'hl7-configuration.rule-configuration.hl7-coding-system',
                )}
              >
                <InputBox
                  data-testid="hl7-configuration--hl7-coding-system"
                  className={classes.input}
                  placeholder="MRN"
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default Hl7RuleConfigurationComponent;
