// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';

import type { ApiError } from '../../../../../api/types';
import { GetMinimumAgeResponse } from '@imprivata-cloud/adminapi-client';

export const minimumAgeConfigurationGetActions = createAsyncAction(
  'settings/ageLimitConfiguration/GET_MINIMUM_AGE_CONFIGURATION_REQUEST',
  'settings/ageLimitConfiguration/GET_MINIMUM_AGE_CONFIGURATION_SUCCESS',
  'settings/ageLimitConfiguration/GET_MINIMUM_AGE_CONFIGURATION_FAILURE',
  'settings/ageLimitConfiguration/GET_MINIMUM_AGE_CONFIGURATION_CANCEL',
)<void, GetMinimumAgeResponse, ApiError, string | void>();

export const minimumAgeConfigurationSaveActions = createAsyncAction(
  'settings/ageLimitConfiguration/SAVE_MINIMUM_AGE_CONFIGURATION_REQUEST',
  'settings/ageLimitConfiguration/SAVE_MINIMUM_AGE_CONFIGURATION_SUCCESS',
  'settings/ageLimitConfiguration/SAVE_MINIMUM_AGE_CONFIGURATION_FAILURE',
  'settings/ageLimitConfiguration/SAVE_MINIMUM_AGE_CONFIGURATION_CANCEL',
)<
  { settingId: string; newMinimumAge: number },
  void,
  ApiError,
  string | void
>();

export const minimumAgeConfigurationClearActions = createAsyncAction(
  'settings/ageLimitConfiguration/CLEAR_MINIMUM_AGE_CONFIGURATION_REQUEST',
  'settings/ageLimitConfiguration/CLEAR_MINIMUM_AGE_CONFIGURATION_SUCCESS',
  'settings/ageLimitConfiguration/CLEAR_MINIMUM_AGE_CONFIGURATION_CANCEL',
  'settings/ageLimitConfiguration/CLEAR_MINIMUM_AGE_CONFIGURATION_FAILURE',
)<
  string,
  void,
  ApiError,
  string | void
>();
