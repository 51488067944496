// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Empty, Skeleton } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import type {
  PatientDecrypted,
  PatientDemographicHistoryDecrypted,
} from 'src/api/types';
import classes from './PatientDemographics.module.less';
import { usePatientSearch } from '../../../patient-search/store/hooks';
import { fetchDemographicsHistory } from '../../../patient-search/store/facades';
interface DemographicUpdate {
  key: string;
  date: string;
  dataUpdated: string;
  previousValue: string;
  newValue: string;
}

interface PatientDemographicUpdatesTableProps {
  patient: PatientDecrypted | null;
}

const PatientDemographicUpdatesTable: React.FC<
  PatientDemographicUpdatesTableProps
> = ({ patient }) => {
  const { t } = useTranslation();
  const { isLoadingDemographicsHistory, demographicUpdates } =
    usePatientSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (patient?.id) {
      fetchDemographicsHistory(patient?.id, dispatch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: DemographicUpdate[] =
    demographicUpdates?.demographicsHistory?.map((item, index) => {
      let formattedDate = '';
      if (item.updatedTimestamp) {
        if (typeof item.updatedTimestamp === 'string') {
          // Convert the string to a moment object, set it to local time, and format it
          formattedDate = moment(item.updatedTimestamp)
            .local()
            .format('YYYY-MM-DD HH:mm');
        } else {
          // Assume it's already a moment object, set it to local time, and format it
          formattedDate = item.updatedTimestamp
            .local()
            .format('YYYY-MM-DD HH:mm');
        }
      }
      return {
        key: index.toString(),
        date: formattedDate || 'N/A',
        dataUpdated: item.updatedField ? item.updatedField : 'N/A',
        previousValue: item.oldValue ? item.oldValue : 'N/A',
        newValue: item.newValue ? item.newValue : 'N/A',
      };
    }) ?? [];

  if (isLoadingDemographicsHistory) {
    return <Skeleton active />;
  }

  return (
    <Table
      dataSource={data}
      rowKey="key"
      data-testid="patient-demographic-updates-table"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('patient-details.no-activity-history')}
          />
        ),
      }}
    >
      <Table.Column<DemographicUpdate>
        title= {() => (
          <span data-testid="patient-demographic-updates-table--sortable-column--header-date">
            {t('patient-details.demographic-updates.date')}
          </span>
        )}
        dataIndex="date"
        key="date"
        onHeaderCell={() => ({
          className: classes.tableHeader,
        })}
        sorter= {{
          compare: (du1, du2) => new Date(du1.date).getTime() - new Date(du2.date).getTime(),
          multiple: 2,
        }}
        defaultSortOrder= 'descend'
        render={(_, record: DemographicUpdate) => (
          <Row data-testid={`patient-demographics--row-${record.key}`}>
            {record.date}
          </Row>
        )}
      />
      <Table.Column<DemographicUpdate>
        title= {() => (
          <span data-testid="patient-demographic-updates-table--sortable-column--header-data-updated">
            {t('patient-details.demographic-updates.data-updated')}
          </span>
        )}
        dataIndex="dataUpdated"
        key="dataUpdated"
        onHeaderCell={() => ({
          className: classes.tableHeader,
        })}
        sorter= {{
          compare: (du1, du2) => du1.dataUpdated.localeCompare(du2.dataUpdated, undefined, { sensitivity: 'base' }),
          multiple: 1,
        }}
        render={(_, record: DemographicUpdate) => (
          <Row data-testid={`patient-demographics--dataUpdated-${record.key}`}>
            {record.dataUpdated}
          </Row>
        )}
      />
      <Table.Column
        title={t('patient-details.demographic-updates.previous-value')}
        dataIndex="previousValue"
        key="previousValue"
        onHeaderCell={() => ({
          className: classes.tableHeader,
        })}
        render={(_, record: DemographicUpdate) => (
          <Row
            data-testid={`patient-demographics--previousValue-${record.key}`}
          >
            {record.previousValue}
          </Row>
        )}
      />
      <Table.Column
        title={t('patient-details.demographic-updates.new-value')}
        dataIndex="newValue"
        key="newValue"
        onHeaderCell={() => ({
          className: classes.tableHeader,
        })}
        render={(_, record: DemographicUpdate) => (
          <Row data-testid={`patient-demographics--newValue-${record.key}`}>
            <Col>{record.newValue}</Col>
          </Row>
        )}
      />
    </Table>
  );
};

export default PatientDemographicUpdatesTable;
